import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The team at PayPerks is honored to be the first start-up featured in the new American Express video series #SpotlightOnChange.`}</p>
    <div className="vid-player-frame mb-3"><iframe width="640" height="390" src="//www.youtube.com/embed/byLDtwK86TM?rel=0" frameBorder="0" allowFullScreen></iframe></div>
    {
      /*more*/
    }
    <p>{`About `}{`#`}{`SpotlightOnChange: This summer, American Express sponsored a groundbreaking documentary called `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=pTd9Z2nCjM0"
      }}>{`Spent: Looking For Change`}</a>{`, which put a spotlight on the struggles of everyday Americans excluded from the financial system.`}</p>
    <p>{`The #SpotlightOnChange (`}<a parentName="p" {...{
        "href": "https://www.facebook.com/hashtag/spotlightonchange"
      }}>{`Facebook`}</a>{`, `}<a parentName="p" {...{
        "href": "https://twitter.com/hashtag/SpotlightOnChange"
      }}>{`Twitter`}</a>{`) series is a followup to the film and highlights a handful of select start-ups and organizations that are working to increase financial inclusion for America’s underserved through innovative products and services.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      